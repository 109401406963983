html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  touch-action: manipulation;
  font-family: "Roboto";

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMedium";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "InkFree";
  src: url("./assets/fonts/inkfree.ttf");
  font-weight: normal;
  font-style: normal;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
